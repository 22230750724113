import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useCommentsCreate, useCommentsPartialUpdate } from '../../aceapi/aceComponents';

const maxBodyCharCount = 4096;

export default function CommentForm({ root = false, edit = null, parent = null, autofocus = false, close }) {
    const { uuid } = useParams();
    const { register, handleSubmit, resetField } = useForm();
    const [charCount, setCharCount] = useState(edit?.body?.length ?? 0);
    const [wasFocused, setWasFocused] = useState(false);

    const queryClient = useQueryClient();

    const { mutateAsync: patchComment } = useCommentsPartialUpdate();
    const { mutateAsync: createComment } = useCommentsCreate();

    const onSubmit = (e) => {
        if (edit) {
            patchComment({ pathParams: { id: edit.id }, body: e })
                .then(async () => {
                    await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'comments'] });
                    close && close();
                })
                .catch((err) => {
                    console.log(err);
                    alert('An unexpected error occurred');
                });
        } else {
            createComment({ body: { ...e, parent, procedure_id: uuid } })
                .then(async () => {
                    resetField('body');
                    setCharCount(0);
                    await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'comments'] });
                    if (!root && close) close();
                })
                .catch((err) => {
                    console.log(err);
                    alert('An unexpected error occurred');
                });
        }
    };

    const handleInput = (e) => {
        const length = e.target.value.length;
        setCharCount(Number.isInteger(length) ? length : 0);
    };

    return (
        <Box m={2} component='form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                    <Typography variant='h5'>
                        {edit ? 'Edit comment' : root ? 'Add new comment' : 'Reply to comment'}
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        label='Displayed Name (optional)'
                        fullWidth
                        inputProps={{ maxLength: 128 }}
                        defaultValue={edit && edit.name}
                        {...register('name')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Comment body'
                        multiline
                        fullWidth
                        required
                        minRows={4}
                        maxRows={20}
                        inputProps={{ maxLength: maxBodyCharCount }}
                        onInput={handleInput}
                        defaultValue={edit && edit.body}
                        helperText={`Remaining characters: ${maxBodyCharCount - charCount}`}
                        inputRef={(input) => {
                            if (autofocus && !wasFocused && input !== null) {
                                setWasFocused(true);
                                input.focus();
                            }
                        }}
                        {...register('body')}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Stack direction='row' spacing={2}>
                        <Button type='submit' variant='contained' disabled={charCount === 0} endIcon={<SendIcon />}>
                            Submit
                        </Button>
                        {!root && (
                            <Button variant='contained' onClick={close}>
                                Cancel
                            </Button>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}
