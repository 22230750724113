import { useNavigate, useParams } from 'react-router-dom';
import Forbidden from '../components/Login/Forbidden';
import PaperCard from '../components/report/PaperCard';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import useFrames from '../components/report/useFrames';
import FrameGrid from '../components/Charts/timeline/FrameGrid';
import format from 'format-duration';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import useDebugOptions from '../components/report/useDebugOptions';
import { types } from '../components/summary/hooks/useSummaryOptions';
import ExtraDataLegend from '../components/report/ExtraDataLegend';
import ConfirmButton from '../components/dialogs/ConfirmButton';
import { DataGrid } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import useExtraData from '../components/report/useExtraData';
import useAuthorized from '../aceapi/hooks/useAuthorized';
import useProcTime from '../aceapi/hooks/useProcTime';
import useShow from '../aceapi/hooks/useShow';
import useVideoSeeker from '../components/stream/useVideoSeeker';
import ProcedureStream from '../components/stream/ProcedureStream';
import useKeyBoardEvent from '../components/events/useKeyBoardEvent';
import ReportTimeline from '../components/Charts/ReportTimeline';
import useVideoKeyNav from '../components/shortcuts/useVideoKeyNav';
import ShortcutCard from '../components/shortcuts/ShortcutCard';
import VideoNavButtons from '../components/stream/VideoNavButtons';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import { useQueryClient } from '@tanstack/react-query';
import {
    useDoctorsCreate,
    useDoctorsList,
    useDoctorsPartialUpdate,
    useEditsTimelineCreate,
    useEditsTimelineDelete,
    useEditsTimelinePartialUpdate,
    useProceduresDoctorDelete,
    useProceduresDoctorRead,
    useProceduresDoctorUpdate,
    useProceduresTimelineEdits,
} from '../aceapi/aceComponents';
import CommentSection from '../components/comments/CommentSection';
import { TokenContext } from '../config/contexts';

const annotation_whitelist = [
    'newpolyp_annotation',
    'caecum_annotation',
    'start_annotation',
    'end_annotation',
    'retroflexion_annotation',
];
const size_choices = ['0_5', '6_10', '11+'];
const visual_diagnosis_choices = ['uncertain', 'adenoma', 'non-adenoma'];
const automated_diagnosis_choices = ['not diagnosed', 'uncertain', 'adenoma', 'non-adenoma', 'conflicting'];

export default function PerformanceEdit(props) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const authorized = useAuthorized();
    const show = useShow();
    const procTime = useProcTime();
    const navigate = useNavigate();
    const token = useContext(TokenContext);
    const user = token.parse().user;
    const isStaff = user.is_active && user.is_staff;
    const userInfo = { username: user.username, isStaff };

    const { extraRow, extraModels, edits, annotations, extraBars } = useExtraData({
        show: show.extra_models,
        authorized,
    });

    const queryClient = useQueryClient();
    const { data: timelineEdits } = useProceduresTimelineEdits(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: authorized && show.timeline, suspense: false },
    );
    const { mutateAsync: createEdit } = useEditsTimelineCreate();
    const { mutateAsync: deleteEdit } = useEditsTimelineDelete();
    const { mutateAsync: partialUpdateEdit } = useEditsTimelinePartialUpdate();

    const { data: doctors } = useDoctorsList({});
    const { mutateAsync: createDoctor } = useDoctorsCreate();
    const { mutateAsync: updateDoctorSite } = useDoctorsPartialUpdate();
    const { data: currentDoctorAssignment } = useProceduresDoctorRead({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });
    const { mutateAsync: updateDoctor } = useProceduresDoctorUpdate();
    const { mutateAsync: unassignDoctor } = useProceduresDoctorDelete();
    const assignedDoctor = useMemo(
        () => doctors?.find((x) => x.id === currentDoctorAssignment?.doctor) ?? null,
        [doctors, currentDoctorAssignment],
    );

    const sites = useMemo(
        () =>
            doctors
                ?.map((x) => x.site)
                ?.filter((x) => x !== null)
                ?.filter((value, index, self) => self.indexOf(value) === index) ?? [],
        [doctors],
    );

    const modelWhitelist = useMemo(
        () => annotation_whitelist.map((x) => extraModels?.find((y) => y.key === x)?.id),
        [extraModels],
    );
    const filteredEdits = useMemo(
        () => timelineEdits?.filter((x) => modelWhitelist.includes(x.model)),
        [timelineEdits, modelWhitelist],
    );

    useEffect(() => {
        edits.setState(filteredEdits ?? []);
    }, [edits, filteredEdits]);

    const noop = useCallback(() => null, []);
    const seeker = useVideoSeeker();
    const { numberOfDetections } = useVideoKeyNav(seeker);

    const frameProps = useFrames({
        uuid,
        setUnavailable: noop,
        authorized,
    });

    const options = useDebugOptions(extraModels !== null, [
        {
            key: 'models',
            type: types.STRING_SELECTOR,
            name: '',
            defaultValue: 8,
            extraProps: {
                options:
                    extraModels
                        ?.filter((x) => annotation_whitelist.includes(x.key))
                        ?.map((x) => ({
                            key: x.key,
                            name: x.name,
                            value: x.id,
                        })) ?? [],
                extraInputProps: { label: 'Model to alter', helperText: 'For annotating use models ending with *' },
            },
        },
    ]);

    const handleAssignDoctor = (doctor) => {
        if (doctor === null) {
            unassignDoctor({ pathParams: { procedureId: uuid } }).then(() => {
                queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'doctor'] });
            });
        } else if (doctor instanceof Object && 'id' in doctor) {
            updateDoctor({ pathParams: { procedureId: uuid }, body: { doctor: doctor.id } }).then(() => {
                queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'doctor'] });
            });
        } else {
            createDoctor({ body: { name: doctor?.name ?? doctor } }).then((response) => {
                updateDoctor({ pathParams: { procedureId: uuid }, body: { doctor: response.id } }).then(() => {
                    queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'doctor'] });
                    queryClient.invalidateQueries({ queryKey: ['doctors'] });
                });
            });
        }
    };

    const handleAssignSite = (site, doctor_id) => {
        if (!doctor_id) {
            alert('Please select a doctor first');
            return;
        }
        if (site instanceof Object) {
            site = site?.site;
        }
        // prompt confirmation
        const confirm = window.confirm(
            `Are you sure you want to assign ${site} to this doctor? This will affect all procedures assigned to this doctor.`,
        );
        if (confirm) {
            updateDoctorSite({ pathParams: { id: doctor_id }, body: { site } }).then(() => {
                queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'doctor'] });
                queryClient.invalidateQueries({ queryKey: ['doctors'] });
            });
        }
    };

    const removeEdit = (id) => {
        deleteEdit({ pathParams: { id } }).then(async () => {
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
        });
    };

    const singleFrameAnnotation = () => {
        if (options.state.models === null) {
            alert('Please select a model to alter');
            return;
        }
        createEdit({
            body: {
                procedure_id: uuid,
                type: 'single',
                model: options.state.models,
                start: seeker.timestamp.state - procTime.start,
                end: null,
                comment: {},
            },
        }).then(async () => {
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
        });
    };

    const downloadAnnotations = () => {
        const data = JSON.stringify(
            annotations.map((x) => ({
                ...x,
                start: x.start + procTime.start,
                end: x.end ? x.end + procTime.start : undefined,
                start_frame: seeker.ts2frame(x.start + procTime.start),
                end_frame: x.end ? seeker.ts2frame(x.end + procTime.start) : undefined,
            })),
        );
        const blob = new Blob([data], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `annotations_${uuid}.json`;
        a.click();
    };

    useKeyBoardEvent({
        watchKeys: ['s'],
        onKeyUp: () => singleFrameAnnotation(),
    });

    useKeyBoardEvent({
        watchKeys: ['p'],
        onKeyUp: () => downloadAnnotations(),
    });

    const frameCell = (x) => {
        const ts = x + procTime.start;
        const frame = seeker.ts2frame(ts);
        return (
            <Link onClick={() => seeker.seekTo(ts)} sx={{ cursor: 'pointer' }}>
                {frame} ({format(ts - seeker.video_start)} | {format(x)})
            </Link>
        );
    };

    const handleChange = (value, cell) => {
        // Handle null case for existing annotations
        let updatedComment = {};

        if (cell.row.comment) {
            updatedComment = cell.row.comment;
        }

        if ([null, undefined, ''].includes(value)) {
            delete updatedComment[cell.field];
        } else {
            updatedComment[cell.field] = value;
        }

        partialUpdateEdit({
            pathParams: { id: cell.row.id },
            body: { comment: updatedComment },
        }).then(async () => {
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
        });
    };

    const getValue = (x) => {
        // Handle null case for existing annotations
        if (!x.row.comment) {
            return null;
        }

        return x.row.comment[x.field]?.valueOf();
    };

    const getModelKey = useCallback(
        (model_id) => {
            return extraModels?.find((x) => x.id === model_id)?.key;
        },
        [extraModels],
    );

    const isModel = useCallback(
        (model_id, model_key) => {
            return getModelKey(model_id) === model_key;
        },
        [getModelKey],
    );

    const polypColumns = [
        {
            field: 'model',
            headerName: 'Model',
            width: 100,
            valueFormatter: (x) => extraModels.find((y) => y.id === x.value)?.name ?? 'Unknown',
        },
        {
            field: 'start',
            headerName: 'Start',
            width: 100,
            renderCell: (x) => frameCell(x.value),
        },
        {
            field: 'visual_diagnosis',
            headerName: 'Visual Diagnosis',
            width: 140,
            renderCell: (x) =>
                isModel(x.row.model, 'newpolyp_annotation') && (
                    <TextField
                        select
                        size='small'
                        onChange={(e) => handleChange(e.target.value, x)}
                        value={getValue(x) ?? ''}
                        sx={{ width: '100%' }}
                    >
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {visual_diagnosis_choices.map((diagnosis) => (
                            <MenuItem key={diagnosis} value={diagnosis}>
                                {diagnosis}
                            </MenuItem>
                        ))}
                    </TextField>
                ),
        },
        {
            field: 'caddie_diagnosis',
            headerName: 'Caddie Diagnosis',
            width: 140,
            renderCell: (x) =>
                isModel(x.row.model, 'newpolyp_annotation') && (
                    <TextField
                        select
                        size='small'
                        onChange={(e) => handleChange(e.target.value, x)}
                        value={getValue(x) ?? ''}
                        sx={{ width: '100%' }}
                    >
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {automated_diagnosis_choices.map((diagnosis) => (
                            <MenuItem key={diagnosis} value={diagnosis}>
                                {diagnosis}
                            </MenuItem>
                        ))}
                    </TextField>
                ),
        },
        {
            field: 'expert_diagnosis',
            headerName: 'Expert Diagnosis',
            width: 140,
            renderCell: (x) =>
                isModel(x.row.model, 'newpolyp_annotation') && (
                    <TextField
                        select
                        size='small'
                        onChange={(e) => handleChange(e.target.value, x)}
                        value={getValue(x) ?? ''}
                        sx={{ width: '100%' }}
                    >
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {visual_diagnosis_choices.map((diagnosis) => (
                            <MenuItem key={diagnosis} value={diagnosis}>
                                {diagnosis}
                            </MenuItem>
                        ))}
                    </TextField>
                ),
        },
        {
            field: 'size',
            headerName: 'Size',
            width: 120,
            renderCell: (x) =>
                isModel(x.row.model, 'newpolyp_annotation') && (
                    <TextField
                        select
                        size='small'
                        onChange={(e) => handleChange(e.target.value, x)}
                        value={getValue(x) ?? ''}
                        sx={{ width: '100%' }}
                    >
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {size_choices.map((size) => (
                            <MenuItem key={size} value={size}>
                                {size}
                            </MenuItem>
                        ))}
                    </TextField>
                ),
        },
        {
            field: 'number of time diagnosed',
            headerName: '# diagnosed',
            width: 100,
            renderCell: (x) =>
                isModel(x.row.model, 'newpolyp_annotation') && (
                    <TextField
                        type='number'
                        size='small'
                        onChange={(e) => handleChange(Math.max(0, e.target.value), x)}
                        value={getValue(x)}
                        sx={{ width: '100%' }}
                    />
                ),
        },
        {
            field: 'id',
            headerName: 'Delete',
            width: 60,
            renderCell: (x) => (
                <ConfirmButton ButtonType={IconButton} onConfirm={() => removeEdit(x.value)} action='delete this edit'>
                    <ClearIcon />
                </ConfirmButton>
            ),
        },
    ];

    const caecumColumns = [
        {
            field: 'model',
            headerName: 'Model',
            flex: 0.2,
            valueFormatter: (x) => extraModels.find((y) => y.id === x.value)?.name ?? 'Unknown',
        },
        {
            field: 'start',
            headerName: 'Start',
            flex: 0.2,
            renderCell: (x) => frameCell(x.value),
        },
        {
            field: 'freeze_frame',
            headerName: 'Freeze frame',
            flex: 0.2,
            renderCell: (x) =>
                isModel(x.row.model, 'caecum_annotation') && (
                    <Checkbox onChange={(e) => handleChange(e.target.checked, x)} checked={getValue(x)} />
                ),
        },
        {
            field: 'diagnosed',
            headerName: 'Correctly Diagnosed',
            flex: 0.2,
            renderCell: (x) =>
                isModel(x.row.model, 'caecum_annotation') && (
                    <Checkbox onChange={(e) => handleChange(e.target.checked, x)} checked={getValue(x)} />
                ),
        },
        {
            field: 'id',
            headerName: 'Delete',
            flex: 0.1,
            renderCell: (x) => (
                <ConfirmButton ButtonType={IconButton} onConfirm={() => removeEdit(x.value)} action='delete this edit'>
                    <ClearIcon />
                </ConfirmButton>
            ),
        },
    ];

    return !authorized || !show.timeline ? (
        <Forbidden />
    ) : (
        <Grid container spacing={3}>
            <PaperCard xs={6} title='Performance annotation' variant='h2' align='center'>
                <ProcedureStream uuid={uuid} seeker={seeker} setUnavailable={noop} noPaper />
                <VideoNavButtons seeker={seeker} />
                <Typography variant='body2'>Number of Detections: {numberOfDetections}</Typography>
                <ReportTimeline
                    setUnavailable={noop}
                    loaded={noop}
                    toggleBars
                    seeker={seeker}
                    extraRow={extraRow}
                    extraBars={extraBars}
                    compact
                    {...frameProps}
                    {...props}
                />
                <Stack spacing={2}>
                    <Typography variant='h6' color='primary' fontFamily='Roboto Mono, Monospace'>
                        Cursor: {seeker.frameInput()} (video: {format(seeker.timestamp.state - seeker.video_start)} |
                        timeline: {format(seeker.timestamp.state - procTime.start)})
                    </Typography>
                    <ExtraDataLegend extraModels={extraModels} />
                    <FrameGrid {...frameProps} />
                </Stack>
            </PaperCard>
            <PaperCard xs={6}>
                <Stack spacing={2}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        {options.render()}
                    </Stack>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Button variant='contained' color='warning' onClick={singleFrameAnnotation}>
                            Single Frame Annotation
                        </Button>
                        <ConfirmButton
                            variant='contained'
                            color='secondary'
                            action='delete all your edits'
                            onConfirm={() => edits.state.map((x) => removeEdit(x.id))}
                        >
                            Reset
                        </ConfirmButton>
                        <ConfirmButton
                            variant='contained'
                            color='primary'
                            action='download annotation data (from models ending with *)'
                            onConfirm={downloadAnnotations}
                        >
                            Export Annotations
                        </ConfirmButton>
                    </Stack>
                    <Typography fontFamily='Roboto Mono, Monospace'>
                        Currently assigned doctor: {assignedDoctor?.name ?? 'None'}
                    </Typography>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Autocomplete
                            freeSolo
                            options={doctors ?? []}
                            getOptionLabel={(option) => option?.name ?? option?.title ?? option}
                            value={assignedDoctor}
                            onChange={(e, value) => handleAssignDoctor(value)}
                            filterOptions={(options, params) => {
                                const filtered = options.filter((x) =>
                                    x.name.toLowerCase().includes(params.inputValue.toLowerCase()),
                                );
                                if (params.inputValue !== '') {
                                    filtered.push({
                                        title: `Add new doctor: ${params.inputValue}`,
                                        name: params.inputValue,
                                    });
                                }
                                return filtered;
                            }}
                            renderOption={(props, option) => (
                                <li {...props}>{option?.title ?? option?.name ?? option}</li>
                            )}
                            renderInput={(params) => <TextField {...params} label='Assign Doctor' />}
                            sx={{ width: '100%' }}
                        />
                    </Stack>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Autocomplete
                            disabled={!assignedDoctor}
                            freeSolo
                            options={sites ?? []}
                            getOptionLabel={(option) => option?.title ?? option}
                            value={assignedDoctor?.site ?? ''}
                            onChange={(e, value) => handleAssignSite(value, assignedDoctor?.id)}
                            filterOptions={(options, params) => {
                                const filtered = options.filter((x) =>
                                    x.toLowerCase().includes(params.inputValue.toLowerCase()),
                                );
                                if (params.inputValue !== '') {
                                    filtered.push({
                                        title: `Add new site: ${params.inputValue}`,
                                        site: params.inputValue,
                                    });
                                }
                                return filtered;
                            }}
                            renderOption={(props, option) => <li {...props}>{option?.title ?? option}</li>}
                            renderInput={(params) => <TextField {...params} label={`${assignedDoctor?.name}'s site`} />}
                            sx={{ width: '100%' }}
                        />
                    </Stack>
                    <DataGrid
                        columns={polypColumns}
                        rows={edits.state.filter((x) => isModel(x.model, 'newpolyp_annotation'))}
                        getRowId={(row) => row.id}
                        autoHeight
                        sortModel={[
                            {
                                field: 'start',
                                sort: 'asc',
                            },
                        ]}
                    />
                    <DataGrid
                        columns={caecumColumns}
                        rows={edits.state.filter((x) => isModel(x.model, 'caecum_annotation'))}
                        getRowId={(row) => row.id}
                        autoHeight
                        sortModel={[
                            {
                                field: 'start',
                                sort: 'asc',
                            },
                        ]}
                    />
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button variant='outlined' onClick={() => navigate(`/${app}/procedures/${uuid}`)}>
                                    Go Back to Procedure
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
            </PaperCard>
            <ShortcutCard xs={6} />
            {show.comments && (
                <PaperCard xs={6} title='Comments' align='center'>
                    <CommentSection {...userInfo} />
                </PaperCard>
            )}
        </Grid>
    );
}
