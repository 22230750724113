import Landing from '../pages/Landing';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import ProcedureDemo from '../pages/ProcedureDemo';
import ProcedureDetail from '../pages/ProcedureDetail';
import About from '../pages/About';
import Search from '../pages/Search';
import ClinicalReportSummary from '../pages/ClinicalReportSummary';
import ProcedureSummary from '../pages/ProcedureSummary';
import ProcedureCompact from '../pages/ProcedureCompact';
import ProcedureCompactDemo from '../pages/ProcedureCompactDemo';
import NotFound from '../pages/NotFound';
import ProcedureTimelineEdit from '../pages/ProcedureTimelineEdit';
import PolypCountEdit from '../pages/PolypCountEdit';

import HospitalDetail from '../pages/HospitalDetail';
import AccountSettings from '../pages/AccountSettings';
import GGPatientReport from '../pages/GGPatientReport';
import CatUploadList from '../pages/CatUploadList';
import PageAppRedirect from '../pages/PageAppRedirect';
import ForbiddenApp from '../pages/ForbiddenApp';
import PerformanceEdit from '../pages/PerformanceEdit';
import ProcedureCAQ from '../pages/ProcedureCAQ';
import ProcedurePerformanceSearch from '../pages/ProcedurePerformanceSearch';

const routeConfig = [
    {
        path: '/',
        component: Landing,
        protected: false,
    },
    {
        path: '/login',
        component: Login,
        protected: false,
    },
    {
        path: '/dashboard',
        component: Dashboard,
        protected: true,
    },
    {
        path: '/summary',
        component: ProcedureSummary,
        protected: true,
    },
    {
        path: '/search',
        component: Search,
        protected: true,
    },
    {
        path: '/performance',
        component: ProcedurePerformanceSearch,
        protected: true,
    },
    {
        path: '/clinicalsummary',
        component: ClinicalReportSummary,
        protected: true,
    },
    {
        path: '/demo/procedure',
        component: ProcedureDemo,
        protected: true,
    },
    {
        path: '/:product/:page/:uuid',
        component: PageAppRedirect,
        protected: true,
    },
    {
        path: '/procedures/:uuid',
        component: ProcedureDetail,
        protected: true,
    },
    {
        path: '/demo/p',
        component: ProcedureCompactDemo,
        protected: true,
    },
    {
        path: '/p/:uuid',
        component: ProcedureCompact,
        protected: true,
    },
    {
        path: '/caq/:uuid',
        component: ProcedureCAQ,
        protected: true,
    },
    {
        path: '/edit/:uuid',
        component: ProcedureTimelineEdit,
        protected: true,
    },
    {
        path: '/review/:uuid',
        component: PolypCountEdit,
        protected: true,
    },
    {
        path: '/perfedit/:uuid',
        component: PerformanceEdit,
        protected: true,
    },
    {
        path: '/patient/:uuid',
        component: GGPatientReport,
        protected: true,
    },
    {
        path: '/hospitals',
        component: HospitalDetail,
        protected: true,
    },
    {
        path: '/cat',
        component: CatUploadList,
        protected: true,
    },
    {
        path: '/about',
        component: About,
        protected: true,
    },
    {
        path: '/settings',
        component: AccountSettings,
        protected: true,
    },
    {
        path: '/error/appForbidden',
        component: ForbiddenApp,
        protected: true,
    },
    {
        path: '*',
        component: NotFound,
        protected: false,
    },
];

export default routeConfig;
