import {
    Avatar,
    Collapse,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useState } from 'react';
import CommentForm from './CommentForm';
import ConfirmButton from '../dialogs/ConfirmButton';
import { useLocation, useParams } from 'react-router-dom';
import styles from '../../resources/css/blink.module.css';
import { useCommentsDelete, useCommentsPartialUpdate } from '../../aceapi/aceComponents';
import { useQueryClient } from '@tanstack/react-query';

const maxOffset = 48;

const dateFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
};

const dateComp = (a, b) => a.getTime() - b.getTime();

export default function Comment({ comment, username, isStaff, getReplies, level = 0, parent = null }) {
    const { uuid } = useParams();
    const { hash } = useLocation();
    const id = `comment-${comment.id}`;
    const selected = hash.replace('#', '');

    const queryClient = useQueryClient();
    const [replying, setReplying] = useState(false);
    const [editing, setEditing] = useState(false);
    const created_at = new Date(comment.created_at);
    const updated_at = new Date(comment.updated_at);
    const replies = getReplies(comment);
    const offset = Math.min(4 * level, maxOffset);

    const { mutateAsync: deleteComment } = useCommentsDelete();
    const { mutateAsync: patchComment } = useCommentsPartialUpdate();

    const deleteSelfCascade = () => {
        setReplying(false);
        setEditing(false);
        deleteComment({ pathParams: { id: comment.id } })
            .then(async () => {
                await queryClient.invalidateQueries({
                    queryKey: ['procedures', uuid, 'comments'],
                });
            })
            .catch((err) => {
                console.log(err);
                alert('An unexpected error occurred');
            });
    };

    const deleteSelfOnly = () => {
        setReplying(false);
        setEditing(false);
        patchComment({ pathParams: { id: comment.id }, body: { name: '[deleted]', body: '[deleted]' } })
            .then(async () => {
                await queryClient.invalidateQueries({
                    queryKey: ['procedures', uuid, 'comments'],
                });
            })
            .catch((err) => {
                console.log(err);
                alert('An unexpected error occurred');
            });
    };

    const replyComment = () => {
        setEditing(false);
        setReplying((prevState) => !prevState);
    };

    const editComment = () => {
        setReplying(false);
        setEditing((prevState) => !prevState);
    };

    return (
        <>
            <ListItem
                id={id}
                className={id === selected ? styles.blink : ''}
                alignItems='flex-start'
                sx={{ pl: offset }}
                secondaryAction={
                    <>
                        <IconButton edge='end' onClick={replyComment}>
                            <Tooltip title='Reply'>
                                <ReplyIcon />
                            </Tooltip>
                        </IconButton>
                        {(isStaff || username === comment?.user?.username) && (
                            <>
                                <IconButton edge='end' onClick={editComment}>
                                    <Tooltip title='Edit'>
                                        <EditIcon />
                                    </Tooltip>
                                </IconButton>
                                <ConfirmButton
                                    edge='end'
                                    ButtonType={IconButton}
                                    onConfirm={deleteSelfOnly}
                                    action='delete this comment (its content will by irreversibly removed but replies will remain)'
                                >
                                    <Tooltip title='Delete'>
                                        <DeleteIcon />
                                    </Tooltip>
                                </ConfirmButton>
                            </>
                        )}
                        {isStaff && (
                            <ConfirmButton
                                edge='end'
                                ButtonType={IconButton}
                                onConfirm={deleteSelfCascade}
                                action='delete this comment and all replies (this action cannot be reverted)'
                            >
                                <Tooltip title='Delete comment & all replies'>
                                    <DeleteForeverIcon sx={{ color: 'red' }} />
                                </Tooltip>
                            </ConfirmButton>
                        )}
                    </>
                }
            >
                <ListItemAvatar>
                    <Avatar alt='avatar' src='/odin.png' />
                </ListItemAvatar>
                <ListItemText
                    sx={{ pr: 8 }}
                    primary={
                        <>
                            <Typography display='inline'>
                                <b>{comment.user.username}</b>
                                {comment.name && `: ${comment.name}`}
                            </Typography>
                            {parent?.id && (
                                <Typography display='inline' color='secondary'>
                                    {' '}
                                    - replying to <b>{parent.user.username}</b>
                                    {parent.name && ` (${parent.name})`}
                                </Typography>
                            )}
                        </>
                    }
                    secondary={
                        <>
                            <Typography display='block' component='span' variant='caption' color='text.primary'>
                                {created_at.toLocaleDateString('en-GB', dateFormatOptions)}
                                {dateComp(created_at, updated_at) !== 0 &&
                                    ` - last edited: ${updated_at.toLocaleDateString('en-GB', dateFormatOptions)}`}
                            </Typography>
                            <Typography component='span'>{comment.body}</Typography>
                        </>
                    }
                />
            </ListItem>
            <Collapse in={replying} unmountOnExit>
                <CommentForm parent={comment.id} uuid={uuid} close={() => setReplying(false)} autofocus />
            </Collapse>
            <Collapse in={editing} unmountOnExit>
                <CommentForm edit={comment} uuid={uuid} close={() => setEditing(false)} autofocus />
            </Collapse>
            {Array.isArray(replies) && replies.length > 0 && (
                <List sx={{ p: 0 }}>
                    {replies?.map((reply) => (
                        <div key={reply.id}>
                            <Divider variant='inset' />
                            <Comment
                                username={username}
                                isStaff={isStaff}
                                uuid={uuid}
                                comment={reply}
                                getReplies={getReplies}
                                level={level + 1}
                                parent={comment}
                            />
                        </div>
                    ))}
                </List>
            )}
        </>
    );
}

export function PlaceHolderComment({ seed = 0 }) {
    const len = ((seed * 97 + (31 - seed * 2) + 5) % 4) + 2;
    return (
        <Stack direction='row' spacing={1}>
            <Skeleton variant='circular' animation='wave' height={50} width={50} />
            <Stack width='100%'>
                <Typography>
                    <Skeleton animation='wave' width={150} />
                </Typography>
                <Typography variant='caption'>
                    <Skeleton animation='wave' width={120} />
                </Typography>
                {Array(len)
                    .fill()
                    .map((_, i) => (
                        <Typography key={i}>
                            <Skeleton animation='wave' width={`${i === len - 1 ? len * 15 : 100}%`} />
                        </Typography>
                    ))}
            </Stack>
        </Stack>
    );
}
