import { useAceApp } from '../Menu/ReportAppSelector';
import useShow from '../../aceapi/hooks/useShow';
import Forbidden from '../Login/Forbidden';
import { useDoctorsList, usePerformanceSearch } from '../../aceapi/aceComponents';
import { useMemo, useState } from 'react';
import ProcedureDataGrid from './ProcedureDataGrid';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Autocomplete, TextField } from '@mui/material';

export default function PerformanceSearch() {
    const { app } = useAceApp();
    const show = useShow();

    const [page, setPage] = useState(0);
    const [doctor, setDoctor] = useState(null);
    const [site, setSite] = useState(null);

    const { data: procs } = usePerformanceSearch({
        queryParams: {
            app,
            page: page + 1,
            ...(doctor && { doctor }),
            ...(site && { site }),
        },
    });

    const { data: doctorData } = useDoctorsList({});
    const doctors = useMemo(() => doctorData?.map((x) => x.name) ?? [], [doctorData]);
    const sites = useMemo(
        () => doctorData?.map((x) => x.site)?.filter((value, index, self) => self.indexOf(value) === index) ?? [],
        [doctorData],
    );

    return !show.timeline_edits ? (
        <Forbidden />
    ) : (
        <Box m={2} pt={3} pb={3}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Autocomplete
                        options={doctors}
                        value={doctor}
                        onChange={(_, value) => {
                            setPage(0);
                            setDoctor(value);
                        }}
                        renderInput={(params) => <TextField {...params} label='Doctor' />}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        options={sites}
                        value={site}
                        onChange={(_, value) => {
                            setPage(0);
                            setSite(value);
                        }}
                        renderInput={(params) => <TextField {...params} label='Site' />}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ProcedureDataGrid
                        rows={procs.results.map((x) => ({ ...x, database: app }))}
                        page={page}
                        disableColumnSorting
                        sortModel={[]}
                        onSortModelChange={() => {}}
                        pageSize={15}
                        onPageChange={setPage}
                        paginationMode='server'
                        rowCount={procs.count}
                        extraColumns={[
                            {
                                field: 'doctor',
                                headerName: 'Doctor',
                            },
                            {
                                field: 'site',
                                headerName: 'Site',
                            },
                        ]}
                        removeColumns={['database', 'status']}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
